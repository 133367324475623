body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(192, 192, 192);
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTextField-root > label {
  left: unset;
}

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  left: unset;
}

.Center-Content {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.Preview-Card {
  margin: 10px 10px 10px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

@supports (display: -webkit-box) {
  .Preview-Card {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: normal;
  }
}

.ProseMirror .column-block {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 24px;
  padding: 8px 0;
}

.ProseMirror .column-readonly {
  overflow: auto;
  border: unset;
  padding: 8px;
  margin: -8px;
}

.ProseMirror .column {
  overflow: auto;
  border: 1px gray dashed;
  border-radius: 8px;
  padding: 8px;
  margin: -8px;
}

.slick-prev:before {
  color: black !important;
  opacity: .5 !important;
}

.slick-next:before {
  color: black !important;
  opacity: .5 !important;
}

.CSVImporter_FileSelector {
  padding: 10em !important;
  min-width: 1000px;
}